import React, { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal, Form, Row, Col, InputNumber, notification, Select } from 'antd';
import { atualizarDescarga, useProdutosRomaneio } from '../../../apis/backend';
import { moegas, filterSelect } from '../../Utils/Utils';

export default NiceModal.create(({ descarga }) => {
    const [form] = Form.useForm();
    const modal = useModal();
    const { produtos, isLoading: isLoadingProdutos } = useProdutosRomaneio();
    const [isLoading, setIsLoading] = useState(false);
    const fieldsRules = [
        {
            required: true,
        },
    ];

    function cancelCreation() {
        form.resetFields();
        modal.hide();
    }

    async function updateDescarga(e) {
        setIsLoading(true);
        e.id = descarga.id;

        try {
            await atualizarDescarga(e);
            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao atualizar entrada/saída.',
            });

            cancelCreation();
        } catch (e) {
            console.error(e);
            notification.error({
                message: 'Erro',
                description: 'Falha ao atualizar entrada/saída. Tente novamente',
            });
        }

        setIsLoading(false);
    }

    return (
        <div>
            <Modal
                title="Atualizar Entrada/Saída"
                centered
                open={modal.visible}
                width="90%"
                destroyOnClose={true}
                closable={false}
                keyboard={false}
                maskClosable={false}
                afterClose={() => modal.remove()}
                footer={[
                    <Button key={1} loading={isLoading} onClick={cancelCreation}>
                        Cancelar
                    </Button>,
                    <Button key={2} type="primary" loading={isLoading} form="descargaForm" htmlType="submit">
                        Atualizar
                    </Button>,
                ]}
            >
                <div>
                    <Form form={form} name="descargaForm" onFinish={updateDescarga} initialValues={descarga}>
                        <Row justify="space-between" gutter={4}>
                            <Col span={14}>
                                <Form.Item label="Produto" name={'produtoId'} rules={fieldsRules}>
                                    <Select loading={isLoadingProdutos} showSearch filterOption={filterSelect} options={produtos} />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item label="Umidade Inicial" name={'umidadeInicial'} rules={fieldsRules}>
                                    <InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item label="Umidade Final" name={'umidadeFinal'} rules={fieldsRules}>
                                    <InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="space-between" gutter={4}>
                            <Col span={24}>
                                <Form.Item label="Local" name={'moega'} rules={fieldsRules}>
                                    <Select options={moegas} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </div>
    );
});
