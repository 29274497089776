import React, { useState, useEffect } from 'react';
import { Descriptions } from 'antd';
import { formatNumber, formatPeso } from './Utils';
import dayjs from 'dayjs';

export const RomaneioImpressao = React.forwardRef((props, ref) => {
    const [romaneio, setRomaneio] = useState(props.romaneio);

    useEffect(() => {
        setRomaneio(props.romaneio);
    }, [props]);

    return (
        <div ref={ref} style={{ height: '100%', minWidth: '100%' }}>
            <div style={{ display: 'flex', height: '100%', minWidth: '100%', padding: '15px', flexDirection: 'column' }}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <img src="https://i.imgur.com/St9BGKw.jpeg" alt="logo" style={{ width: '50%', marginBottom: '20px', filter: 'grayscale(100%)' }}></img>
                </div>
                <Descriptions bordered column={1} style={{ width: '100%' }} size="small">
                    <Descriptions.Item label="Tipo">{romaneio?.tipo || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Produto">{romaneio?.produto?.descricao || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Data Entrada">{`${dayjs(romaneio?.createdDate + 'Z').format('DD/MM/YYYY HH:mm')}`}</Descriptions.Item>
                    <Descriptions.Item label="Data Saída">{romaneio?.dataSaida ? `${dayjs(romaneio?.dataSaida + 'Z').format('DD/MM/YYYY HH:mm')}` : 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Transgenia">{romaneio?.transgenia || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Cliente">{romaneio?.cliente?.nome || romaneio?.cliente?.razaoSocial}</Descriptions.Item>
                    <Descriptions.Item label="Placa">{romaneio?.placaVeiculo || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Tipo Veículo">{romaneio?.tipoVeiculo || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Motorista">{romaneio?.motoristaNome || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="CPF">{romaneio?.motoristaCPF || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Celular">{romaneio?.motoristaCelular || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Local">{romaneio?.moega || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Peso Entrada (KG)">{formatNumber(romaneio?.pesoEntrada) || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Peso Saída (KG)">{formatNumber(romaneio?.pesoSaida) || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Peso Bruto (KG)">{formatNumber(romaneio?.pesoBruto) || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Umidade / Desconto">{romaneio?.umidade ? `${romaneio?.umidade} % | ${formatPeso(romaneio?.umidadePeso)}` : 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Impureza / Desconto">
                        {romaneio?.impureza ? `${romaneio?.impureza} % | ${formatPeso(romaneio?.impurezaPeso)}` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Avariados / Desconto">
                        {romaneio?.avariados ? `${romaneio?.avariados} % | ${formatPeso(romaneio?.avariadosPeso)}` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="PS / Desconto">
                        {romaneio?.prestacaoServico ? `${romaneio?.prestacaoServico} % | ${formatPeso(romaneio?.prestacaoServicoPeso)}` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Peso Liquído (KG)">{formatNumber(romaneio?.pesoLiquido) || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Observações">{romaneio?.observacoes}</Descriptions.Item>
                </Descriptions>
            </div>
        </div>
    );
});
