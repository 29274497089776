import useSWR from 'swr';
import { fetcher } from '../backendFetcher';
import backendApi from '../backendAxiosClient';

export function useRelatorioTransgenia(startDate, endDate, clientes, tipo) {
    const { data, error, mutate } = useSWR(
        `relatorios/transgenia?startDate=${startDate}&endDate=${endDate}&clientesId=${clientes.toString() || ''}&tipo=${tipo.toString() || ''}`,
        fetcher
    );

    return {
        relatorio: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export async function postTransgenia(romaneioId) {
    return await backendApi.post(`relatorios/transgenia?romaneioId=${romaneioId}`, {});
}

export function useRelatorioEstoque(clientes, produto) {
    const { data, error, mutate } = useSWR(`relatorios/estoques-cliente?clientesId=${clientes.toString() || ''}&produtosId=${produto.toString() || ''}`, fetcher, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });

    return {
        relatorio: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useRelatorioContratoMovimentacoes(startDate, endDate, clientes, produtos, opFiscais) {
    const { data, error, mutate } = useSWR(
        `relatorios/movimentacoes-contrato?startDate=${startDate}&endDate=${endDate}&clientesId=${clientes.toString() || ''}&produtosId=${produtos.toString() || ''}&ops=${
            opFiscais.toString() || ''
        }`,
        fetcher,
        {
            revalidateOnFocus: false,
            refreshInterval: 0,
        }
    );

    return {
        relatorio: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useRelatorioMovimentacoesEstoque(startDate, endDate, clientes, produtos, tipos) {
    const { data, error, mutate } = useSWR(
        `relatorios/movimentacoes-estoque?startDate=${startDate}&endDate=${endDate}&clientesId=${clientes.toString() || ''}&produtosId=${produtos.toString() || ''}&tiposParam=${
            tipos.toString() || ''
        }`,
        fetcher,
        {
            revalidateOnFocus: false,
            refreshInterval: 0,
        }
    );

    return {
        relatorio: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useRelatorioArmazenamentoExpedicao(startDate, endDate, clientes, produto, tipo) {
    const { data, error, mutate } = useSWR(
        `relatorios/armazenamento-expedicao?startDate=${startDate}&endDate=${endDate}&clientesId=${clientes.toString() || ''}&produtoId=${produto}&tipoParam=${tipo}`,
        fetcher,
        {
            revalidateOnFocus: false,
            refreshInterval: 0,
        }
    );

    return {
        relatorio: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useRelatorioPropriedadesoMedia(startDate, endDate, clientes, produto) {
    const { data, error, mutate } = useSWR(
        `relatorios/propriedades-medias?startDate=${startDate}&endDate=${endDate}&clientesId=${clientes.toString() || ''}&produtoId=${produto}`,
        fetcher,
        {
            revalidateOnFocus: false,
            refreshInterval: 0,
        }
    );

    return {
        relatorio: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useRelatorioFretes(startDate, endDate, cliente, produto, motorista) {
    const { data, error, mutate } = useSWR(
        `relatorios/fretes?startDate=${startDate}&endDate=${endDate}&clientesId=${cliente.toString()}&produtosId=${produto.toString() || ''}&motoristas=${motorista || ''}`,
        fetcher
    );

    return {
        relatorio: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useRelatorioContratos(startDate, endDate, tipos, compradores, vendedores, produto, rangePagamentoStart, rangePagamentoEnd) {
    const { data, error, mutate } = useSWR(
        `relatorios/contratos?startDate=${startDate}&endDate=${endDate}&compradoresId=${compradores.toString()}&vendedoresId=${vendedores.toString()}&produtosId=${
            produto ? produto.toString() : ''
        }&tipos=${tipos || ''}&pagamentoStart=${rangePagamentoStart}&pagamentoEnd=${rangePagamentoEnd}`,
        fetcher
    );

    return {
        relatorio: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useRelatorioClientes(clientesIds) {
    const { data, error, mutate } = useSWR(`relatorios/clientes?clientesIds=${clientesIds}`, fetcher);

    return {
        relatorio: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useRelatorioAssinaturas(type) {
    const { data, error, mutate } = useSWR(`relatorios/assinaturas?type=${type}`, fetcher);

    return {
        relatorio: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}
