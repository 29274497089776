import React, { useState, useEffect } from 'react';
import { useGraphQL } from '../../../../apis/backendGraphQL';
import { getRomaneiosPendentes } from '../../../../apis/queries/graphQueries';
import { Input, Table, Space, Tooltip, Button } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { searchInArray, getCustomerName } from '../../../Utils/Utils';
import { useModal } from '@ebay/nice-modal-react';
import { Link } from 'react-router-dom';
import { PlusOutlined, PrinterOutlined } from '@ant-design/icons';
import CriarRomaneio from '../CriarRomaneio/CriarRomaneio';
import RomaneioActions from '../RomaneioActions/RomaneioActions';
import ImprimirRomaneiosAbertos from '../ImprimirRomaneiosAbertos/ImprimirRomaneiosAbertos';

const { Search } = Input;

export default function RomaneiosAbertos() {
    const { data, isLoading: isLoadingRomaneios, mutate } = useGraphQL(getRomaneiosPendentes);
    const [filteredRomaneioData, setFilteredRomaneioData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const criarRomaneioModal = useModal(CriarRomaneio);
    const columns = [
        {
            title: 'Posição',
            key: 'id',
            dataIndex: 'id',
            render: (item, record, index) => {
                const posicaoRelativa = filteredRomaneioData.findIndex((x) => x.id === record.id) + 1;
                const posicaoGeral = data?.romaneios.findIndex((x) => x.id === record.id) + 1;

                return `${posicaoRelativa} (${posicaoGeral})`;
            },
            width: '85px',
        },
        {
            title: 'Placa',
            dataIndex: 'placaVeiculo',
            ellipsis: true,
            width: '100px',
        },
        {
            title: 'Motorista',
            dataIndex: 'motoristaNome',
            ellipsis: true,
        },
        {
            title: 'Local',
            dataIndex: 'moega',
            ellipsis: true,
        },
        {
            title: 'Tipo',
            dataIndex: 'tipo',
            ellipsis: true,
            width: '83px',
        },
        {
            title: 'Cliente',
            render: (_, r) => (
                <Link target="_blank" to={`/cadastros/cliente/${r.clienteId}`}>
                    {getCustomerName(r.cliente)}
                </Link>
            ),
            ellipsis: true,
        },
        {
            title: 'Produto',
            dataIndex: ['produto', 'descricao'],
            ellipsis: true,
            width: '160px',
        },
        {
            title: 'Ações',
            key: 'action',
            render: (_, record) => <RomaneioActions romaneio={record} onAfterAction={mutateData} />,
        },
    ];

    useEffect(() => {
        setFilteredRomaneioData(data?.romaneios);
    }, [data]);

    function onSearch(evt) {
        const searchTerm = evt.target.value;

        if (!searchTerm) return setFilteredRomaneioData(data?.romaneios);

        setFilteredRomaneioData(searchInArray(searchTerm, data?.romaneios));
    }

    function criarRomaneio() {
        criarRomaneioModal.show().then(async () => {
            mutateData();
        });
    }

    function imprimirLista() {
        NiceModal.show(ImprimirRomaneiosAbertos, { filteredRomaneioData, data });
    }

    async function mutateData() {
        setIsLoading(true);
        await mutate();
        setIsLoading(false);
    }

    return (
        <span>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ width: '90%' }}>
                    <Search placeholder="Busca" allowClear size="large" onChange={onSearch} />
                </div>
                <div style={{ width: '5%', display: 'flex', justifyContent: 'center', paddingLeft: '10px' }}>
                    <Tooltip title="Criar Romaneio">
                        <Button type="primary" size={'large'} shape="circle" onClick={criarRomaneio} icon={<PlusOutlined />} />
                    </Tooltip>
                </div>
                <div style={{ width: '5%', display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title="Imprimir Lista">
                        <Button type="primary" size={'large'} shape="circle" onClick={imprimirLista} icon={<PrinterOutlined />} />
                    </Tooltip>
                </div>
            </div>
            <Space size="middle">
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={filteredRomaneioData}
                    pagination={{
                        simple: false,
                        hideOnSinglePage: true,
                        defaultPageSize: 10,
                        showSizeChanger: false,
                    }}
                    loading={isLoadingRomaneios || isLoading}
                />
            </Space>
        </span>
    );
}
