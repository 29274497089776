import React, { useEffect, useState } from 'react';
import { Breadcrumb, Divider, Spin, Row, Col, Form, notification, Button, Input, Tabs, Select, InputNumber, DatePicker, Popover, Space, Typography } from 'antd';
import { ProPageHeader } from '@ant-design/pro-layout';
import { Link, useNavigate } from 'react-router-dom';
import { getDetalheLancamento } from '../../../apis/queries/graphQueries';
import { useGraphQL } from '../../../apis/backendGraphQL';
import { getContratos, getSelectClientes, getCaixas, getSelectFornecedores } from '../../../apis/queries/graphQueries';
import {
    filterSelect,
    getCustomerName,
    formatDayDate,
    formatPeso,
    lancamentoTipos,
    lancamentoStatus,
    lancamentoCategorias,
    reaisFormatter,
    reaisParser,
    formasPagamento,
    unidadesTipos,
} from '../../Utils/Utils';
import { updateLancamento } from '../../../apis/backend';
import dayjs from 'dayjs';
import LancamentoTransacoesRelacionadas from '../LancamentoTransacoesRelacionadas/LancamentoTransacoesRelacionadas';

const { Option } = Select;
const { Text } = Typography;

export default function DetalheLancamento(props) {
    const { data, isLoading } = useGraphQL(getDetalheLancamento, { lancamentoId: parseInt(props.id) });
    const { data: contratos, isLoading: isLoadingContratosRelacionados } = useGraphQL(getContratos);
    const { data: clientes, isLoading: loadingClientes } = useGraphQL(getSelectClientes);
    const { data: fornecedores, isLoading: loadingFornecedores } = useGraphQL(getSelectFornecedores);
    const { data: caixas, isLoading: loadingCaixas } = useGraphQL(getCaixas);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [activeTab, setActiveTab] = useState('1');
    const [pageLoading, setPageLoading] = useState(false);
    const fieldsRules = [
        {
            required: true,
        },
    ];

    useEffect(
        (x) => {
            const lancamento = data?.lancamentos[0];
            if (!lancamento) return;
            lancamento.vencimento = dayjs(lancamento.vencimento);
            form.setFieldsValue(lancamento);
        },
        [data]
    );

    async function update(e) {
        setPageLoading(true);

        e.id = parseInt(props.id);

        try {
            await updateLancamento(e);

            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao atualizar lançamento.',
            });
        } catch (e) {
            notification.error({
                message: 'Erro',
                description: e,
            });
        }

        setPageLoading(false);
    }

    const DetalheLancamentoForm = () => {
        return (
            <div>
                <Form form={form} name="lancamentoForm" onFinish={update}>
                    <Row justify="space-between" gutter={4}>
                        <Col span={12}>
                            <Form.Item label="Tipo" name={'tipo'} rules={fieldsRules}>
                                <Select showSearch filterOption={true} onSearch={filterSelect} options={lancamentoTipos} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Categoria" name={'categoria'} rules={fieldsRules}>
                                <Select showSearch filterOption={true} onSearch={filterSelect} options={lancamentoCategorias} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" gutter={4}>
                        <Col span={6}>
                            <Form.Item label="Unidade" name={'unidade'}>
                                <Select options={unidadesTipos} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Quantidade" name={'quantidade'} rules={fieldsRules}>
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={14}>
                            <Form.Item label="Descrição" name={'descricao'} rules={fieldsRules}>
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" gutter={4}>
                        <Col span={8}>
                            <Form.Item label="Status" name={'status'} rules={fieldsRules}>
                                <Select showSearch filterOption={true} onSearch={filterSelect} options={lancamentoStatus} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Caixa" name={'caixaId'}>
                                <Select loading={loadingCaixas} showSearch filterOption={filterSelect}>
                                    {caixas?.caixas?.map((val) => {
                                        return (
                                            <Option key={val.id} value={val.id}>
                                                {val.nome}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Vencimento" name={'vencimento'} rules={fieldsRules}>
                                <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" gutter={4}>
                        <Col span={8}>
                            <Form.Item label="Cliente" name={'clienteId'}>
                                <Select allowClear showSearch loading={loadingClientes} filterOption={filterSelect} options={clientes?.clientes} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Fornecedor" name={'fornecedorId'}>
                                <Select allowClear showSearch loading={loadingFornecedores} filterOption={filterSelect} options={fornecedores?.clientes} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Contrato" name={'contratoId'}>
                                <Select loading={isLoadingContratosRelacionados} showSearch filterOption={filterSelect}>
                                    {!!contratos && !!contratos.contratos
                                        ? contratos.contratos.map((val) => {
                                              return (
                                                  <Option key={val.id} value={val.id} label={`${getCustomerName(val.comprador)} | ${getCustomerName(val.vendedor)}`}>
                                                      <Popover
                                                          placement="right"
                                                          title={
                                                              <div>
                                                                  {val.numeroDeContrato} |{' '}
                                                                  <Link target="_blank" to={`/cadastros/contrato/${val.id}`}>
                                                                      Acessar
                                                                  </Link>
                                                              </div>
                                                          }
                                                          content={
                                                              <div>
                                                                  <Text type="secondary">Comprador: </Text>
                                                                  <Text>{getCustomerName(val.comprador)}</Text>
                                                                  <br />
                                                                  <Text type="secondary">Vendedor: </Text>
                                                                  <Text>{getCustomerName(val.vendedor)}</Text>
                                                                  <br />
                                                                  <Text type="secondary">Pagamento: </Text>
                                                                  <Text>{formatDayDate(val.dataDePagamento, true)}</Text>
                                                                  <br />
                                                                  <Text type="secondary">Prazo Embarque: </Text>
                                                                  <Text>{formatDayDate(val.prazoDeEmbarque, true)}</Text>
                                                                  <br />
                                                                  <Text type="secondary">Contrato Externo: </Text>
                                                                  <Text>{val.contratoExterno}</Text>
                                                                  <br />
                                                                  <Text type="secondary">Quantidade Total: </Text>
                                                                  <Text>{formatPeso(val.quantidadeTotal)}</Text>
                                                                  <br />
                                                                  <Text type="secondary">Quantidade Embarcada: </Text>
                                                                  <Text>{formatPeso(val.quantidadeEmbarcada)}</Text>
                                                                  <br />
                                                                  <Text type="secondary">Saldo Embarcar: </Text>
                                                                  <Text>{formatPeso(val.saldoAEmbarcar)}</Text>
                                                                  <br />
                                                              </div>
                                                          }
                                                      >
                                                          <Space>{`${getCustomerName(val.comprador)} | ${getCustomerName(val.vendedor)}`}</Space>
                                                      </Popover>
                                                  </Option>
                                              );
                                          })
                                        : []}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" gutter={4}>
                        <Col span={8}>
                            <Form.Item label="Forma de Pagamento" name={'formaDePagamento'}>
                                <Select allowClear showSearch filterOption={filterSelect} options={formasPagamento} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="NF" name={'nf'}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="Nr. Cheque" name={'cheque'}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item label="Valor em Cheque" name={'valorEmCheque'} rules={fieldsRules}>
                                <InputNumber style={{ width: '100%' }} addonBefore={'R$'} formatter={(value) => reaisFormatter(value)} parser={(value) => reaisParser(value)} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" gutter={4}>
                        <Col span={6}>
                            <Form.Item label="Valor" name={'valor'} rules={fieldsRules}>
                                <InputNumber style={{ width: '100%' }} addonBefore={'R$'} formatter={(value) => reaisFormatter(value)} parser={(value) => reaisParser(value)} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Valor Total" name={'valorTotal'} rules={fieldsRules}>
                                <InputNumber
                                    disabled={true}
                                    style={{ width: '100%' }}
                                    addonBefore={'R$'}
                                    formatter={(value) => reaisFormatter(value)}
                                    parser={(value) => reaisParser(value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Relacionado" name={'relatedValue'}>
                                <InputNumber
                                    disabled={true}
                                    style={{ width: '100%' }}
                                    addonBefore={'R$'}
                                    formatter={(value) => reaisFormatter(value)}
                                    parser={(value) => reaisParser(value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Valor a Pagar" name={'missingRelatedValue'}>
                                <InputNumber
                                    disabled={true}
                                    style={{ width: '100%' }}
                                    addonBefore={'R$'}
                                    formatter={(value) => reaisFormatter(value)}
                                    parser={(value) => reaisParser(value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" gutter={4}>
                        <Col span={6}>
                            <Form.Item label="Descontos" name={'descontos'}>
                                <InputNumber style={{ width: '100%' }} addonBefore={'R$'} formatter={(value) => reaisFormatter(value)} parser={(value) => reaisParser(value)} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Juros" name={'juros'}>
                                <InputNumber style={{ width: '100%' }} addonBefore={'R$'} formatter={(value) => reaisFormatter(value)} parser={(value) => reaisParser(value)} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Multas" name={'multas'}>
                                <InputNumber style={{ width: '100%' }} addonBefore={'R$'} formatter={(value) => reaisFormatter(value)} parser={(value) => reaisParser(value)} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Acréscimos" name={'acrescimos'}>
                                <InputNumber style={{ width: '100%' }} addonBefore={'R$'} formatter={(value) => reaisFormatter(value)} parser={(value) => reaisParser(value)} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Divider orientation="left">Transações</Divider>
                <LancamentoTransacoesRelacionadas lancamentoId={parseInt(props.id)}></LancamentoTransacoesRelacionadas>
            </div>
        );
    };

    const items = [
        {
            key: '1',
            label: 'Informações Gerais',
            children: <DetalheLancamentoForm />,
        },
    ];

    return (
        <div>
            <Spin tip="Carregando..." spinning={isLoading || pageLoading}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>Financeiro</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/financeiro/lancamentos/`}>Lançamentos</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/financeiro/lancamento/${props.id}`}>{data?.lancamentos[0]?.descricao}</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Divider />
                <ProPageHeader
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    title={data?.lancamentos[0]?.descricao}
                    className="site-page-header"
                    onBack={() => navigate('/financeiro/lancamentos/')}
                    extra={[
                        <Button type="primary" form="lancamentoForm" key="1" htmlType="submit" disabled={activeTab !== '1'}>
                            Salvar
                        </Button>,
                    ]}
                ></ProPageHeader>
                <div>
                    <Tabs defaultActiveKey="1" onChange={setActiveTab} items={items} />
                </div>
            </Spin>
        </div>
    );
}
