import React, { useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Row, Col, Button, Modal, DatePicker, Select, Form, Space, Popover, Typography, InputNumber, Input, notification, Checkbox, Divider } from 'antd';
import {
    filterSelect,
    getCustomerName,
    formatDayDate,
    formatPeso,
    lancamentoTipos,
    lancamentoStatus,
    lancamentoCategorias,
    reaisFormatter,
    reaisParser,
    formasPagamento,
    unidadesTipos,
} from '../../Utils/Utils';
import { useGraphQL } from '../../../apis/backendGraphQL';
import { criarLancamento } from '../../../apis/backend';
import { useNavigate } from 'react-router-dom';
import { getContratos, getSelectClientes, getCaixas, getSelectFornecedores } from '../../../apis/queries/graphQueries';
import { Link } from 'react-router-dom';

const { Option } = Select;
const { Text } = Typography;

export default NiceModal.create(() => {
    const { data: contratos, isLoading: isLoadingContratosRelacionados } = useGraphQL(getContratos);
    const { data: clientes, isLoading: loadingClientes } = useGraphQL(getSelectClientes);
    const { data: fornecedores, isLoading: loadingFornecedores } = useGraphQL(getSelectFornecedores);
    const { data: caixas, isLoading: loadingCaixas } = useGraphQL(getCaixas);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const modal = useModal();
    const [form] = Form.useForm();
    const fieldsRules = [
        {
            required: true,
        },
    ];

    async function create(payload) {
        setIsLoading(true);

        if (!payload.contratoId && !payload.clienteId && !payload.fornecedorId) {
            notification.error({
                message: 'Erro',
                description: 'É necessário informar um contrato ou um cliente ou um fornecedor.',
            });
            setIsLoading(false);
            return;
        }

        try {
            navigate(`/financeiro/lancamento/${await criarLancamento(payload)}`);
            modal.hide();
        } catch (e) {
            setIsLoading(false);

            notification.error({
                message: 'Erro',
                description: 'Falha ao criar lançamento. Tente novamente. ' + e,
            });
        }
    }

    function cancelCreation(shouldMutate) {
        if (shouldMutate) modal.resolve();

        modal.hide();
    }

    function onSelectTipo(value) {
        const tipo = lancamentoTipos.find((val) => val.value === value);

        if (lancamentoTipos) {
            form.setFieldsValue({
                categoria: tipo.type,
            });
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            quantidade: 0,
            tipo: lancamentoTipos[0].value,
            categoria: lancamentoCategorias[0].value,
            status: lancamentoStatus[0].value,
            recorrencia: 0,
        });
    }, []);

    const CriarLancamentoForm = () => {
        return (
            <Form form={form} name="lancamentoform" onFinish={create}>
                <Row justify="space-between" gutter={4}>
                    <Col span={12}>
                        <Form.Item label="Tipo" name={'tipo'} rules={fieldsRules}>
                            <Select showSearch filterOption={true} onSearch={filterSelect} options={lancamentoTipos} onSelect={onSelectTipo} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Categoria" name={'categoria'} rules={fieldsRules}>
                            <Select showSearch filterOption={true} onSearch={filterSelect} options={lancamentoCategorias} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" gutter={4}>
                    <Col span={6}>
                        <Form.Item label="Unidade" name={'unidade'}>
                            <Select options={unidadesTipos} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Quantidade" name={'quantidade'} rules={fieldsRules}>
                            <InputNumber defaultValue={0} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item label="Descrição" name={'descricao'} rules={fieldsRules}>
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" gutter={4}>
                    <Col span={8}>
                        <Form.Item label="Status" name={'status'} rules={fieldsRules}>
                            <Select showSearch filterOption={true} onSearch={filterSelect} options={lancamentoStatus} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Caixa" name={'caixaId'}>
                            <Select loading={loadingCaixas} showSearch filterOption={filterSelect}>
                                {caixas?.caixas?.map((val) => {
                                    return <Option value={val.id}>{val.nome}</Option>;
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Vencimento" name={'vencimento'} rules={fieldsRules}>
                            <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" gutter={4}>
                    <Col span={8}>
                        <Form.Item label="Cliente" name={'clienteId'}>
                            <Select allowClear showSearch loading={loadingClientes} filterOption={filterSelect} options={clientes?.clientes} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Fornecedor" name={'fornecedorId'}>
                            <Select allowClear showSearch loading={loadingFornecedores} filterOption={filterSelect} options={fornecedores?.clientes} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Contrato" name={'contratoId'}>
                            <Select loading={isLoadingContratosRelacionados} showSearch filterOption={filterSelect}>
                                {!!contratos && !!contratos.contratos
                                    ? contratos.contratos.map((val) => {
                                          return (
                                              <Option value={val.id} label={`${getCustomerName(val.comprador)} | ${getCustomerName(val.vendedor)}`}>
                                                  <Popover
                                                      placement="right"
                                                      title={
                                                          <div>
                                                              {val.numeroDeContrato} |{' '}
                                                              <Link target="_blank" to={`/cadastros/contrato/${val.id}`}>
                                                                  Acessar
                                                              </Link>
                                                          </div>
                                                      }
                                                      content={
                                                          <div>
                                                              <Text type="secondary">Comprador: </Text>
                                                              <Text>{getCustomerName(val.comprador)}</Text>
                                                              <br />
                                                              <Text type="secondary">Vendedor: </Text>
                                                              <Text>{getCustomerName(val.vendedor)}</Text>
                                                              <br />
                                                              <Text type="secondary">Pagamento: </Text>
                                                              <Text>{formatDayDate(val.dataDePagamento, true)}</Text>
                                                              <br />
                                                              <Text type="secondary">Prazo Embarque: </Text>
                                                              <Text>{formatDayDate(val.prazoDeEmbarque, true)}</Text>
                                                              <br />
                                                              <Text type="secondary">Contrato Externo: </Text>
                                                              <Text>{val.contratoExterno}</Text>
                                                              <br />
                                                              <Text type="secondary">Quantidade Total: </Text>
                                                              <Text>{formatPeso(val.quantidadeTotal)}</Text>
                                                              <br />
                                                              <Text type="secondary">Quantidade Embarcada: </Text>
                                                              <Text>{formatPeso(val.quantidadeEmbarcada)}</Text>
                                                              <br />
                                                              <Text type="secondary">Saldo Embarcar: </Text>
                                                              <Text>{formatPeso(val.saldoAEmbarcar)}</Text>
                                                              <br />
                                                          </div>
                                                      }
                                                  >
                                                      <Space>{`${getCustomerName(val.comprador)} | ${getCustomerName(val.vendedor)}`}</Space>
                                                  </Popover>
                                              </Option>
                                          );
                                      })
                                    : []}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" gutter={4}>
                    <Col span={4}>
                        <Form.Item label="Valor" name={'valor'} rules={fieldsRules}>
                            <InputNumber style={{ width: '100%' }} addonBefore={'R$'} formatter={(value) => reaisFormatter(value)} parser={(value) => reaisParser(value)} />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item label="Forma de Pagamento" name={'formaDePagamento'}>
                            <Select allowClear showSearch filterOption={filterSelect} options={formasPagamento} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="NF" name={'nf'}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Nr. Cheque" name={'cheque'}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" gutter={4}>
                    <Col span={6}>
                        <Form.Item label="Descontos" name={'descontos'}>
                            <InputNumber style={{ width: '100%' }} addonBefore={'R$'} formatter={(value) => reaisFormatter(value)} parser={(value) => reaisParser(value)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Juros" name={'juros'}>
                            <InputNumber style={{ width: '100%' }} addonBefore={'R$'} formatter={(value) => reaisFormatter(value)} parser={(value) => reaisParser(value)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Multas" name={'multas'}>
                            <InputNumber style={{ width: '100%' }} addonBefore={'R$'} formatter={(value) => reaisFormatter(value)} parser={(value) => reaisParser(value)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Acréscimos" name={'acrescimos'}>
                            <InputNumber style={{ width: '100%' }} addonBefore={'R$'} formatter={(value) => reaisFormatter(value)} parser={(value) => reaisParser(value)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" gutter={4}>
                    <Col span={4}>
                        <Form.Item label="Repetir" name={'recorrencia'}>
                            <InputNumber addonAfter={'Meses'} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Modal
            title="Criar Lançamento"
            centered
            open={modal.visible}
            width="90%"
            destroyOnClose={true}
            closable={false}
            keyboard={false}
            maskClosable={false}
            afterClose={() => modal.remove()}
            footer={[
                <Button key={1} onClick={cancelCreation} isLoading={isLoading}>
                    Cancelar
                </Button>,
                <Button key={2} type="primary" form="lancamentoform" htmlType="submit" disabled={isLoading} isLoading={isLoading}>
                    Salvar
                </Button>,
            ]}
        >
            <CriarLancamentoForm />
        </Modal>
    );
});
