import React, { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { Col, Divider, Row, Typography } from 'antd';
import { getRomaneiosPendentes } from '../../apis/queries/graphQueries';
import { useGraphQL } from '../../apis/backendGraphQL';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import useSound from 'use-sound';
import boopSfx from './bereal.mp3';
import './ChamadaApp.css';

export default function ChamadaApp() {
    const url = window.location.hostname === 'localhost' ? 'http://127.0.0.1:5001/notificacao-hub' : `https://be-bo.limaetarifa.com.br/notificacao-hub`;
    const [connectionStatus, setConnectionStatus] = useState('Conectando');
    const { data, isLoading: isLoadingRomaneios, mutate } = useGraphQL(getRomaneiosPendentes);
    const [orderedRomaneio, setOrderedRomaneio] = useState([]);
    const [chamadas, setChamadas] = useState([]);
    const [isFlashing, setIsFlashing] = useState(false);
    const [play] = useSound(boopSfx);

    useEffect(() => {
        const connection = getConnection();
        startConnection(connection);
    }, []);

    useEffect(() => {
        if (data?.romaneios) {
            let fixedRomaneios = {
                'Sem local': [],
                Saída: [],
            };
            data?.romaneios.forEach((romaneio) => {
                let moega = romaneio.moega || 'Sem local';
                if (romaneio.tipo === 'Saída') moega = 'Saída';
                fixedRomaneios[moega] = [...(fixedRomaneios[moega] || []), romaneio];
            });
            setOrderedRomaneio(fixedRomaneios);
        }
    }, [data]);

    function getConnection() {
        const connection = new HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build();

        connection.onclose(() => onCloseConnection());
        connection.onreconnecting(() => setConnectionStatus('Reconectando'));

        return connection;
    }

    function onCloseConnection() {
        setConnectionStatus('Desconectado');
    }

    async function startConnection(connection) {
        if (!connection || connection.state === 'Connected') return;

        try {
            await connection.start();
            connection.on('Notificacao', onMsgReceived);
            setConnectionStatus('Conectado');
        } catch (error) {
            console.error('Error starting connection', error);
            setConnectionStatus('Desconectado');
        }
    }

    function onMsgReceived(message) {
        setChamadas((prevChamadas) => {
            const lastCall = prevChamadas[prevChamadas.length - 1];
            if (lastCall && lastCall.romaneio.id === message.romaneio.id && lastCall.notificationType === message.notificationType) {
                return prevChamadas;
            }
            return [...prevChamadas, message];
        });
    }

    function playSoundFiveTimes() {
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        async function playWithDelay() {
            for (let i = 0; i < 5; i++) {
                play();
                await delay(1000); // Espera  1 segundo
            }
        }

        playWithDelay();
    }

    useEffect(() => {
        if (chamadas.length > 0) {
            setIsFlashing(true);
            playSoundFiveTimes();
            setTimeout(() => {
                setIsFlashing(false);
            }, 5000); // Remove a classe após  5 segundos
        }
    }, [chamadas]);

    function ChamadaResumo({ chamada }) {
        const truncateMotorista = (motorista) => {
            return motorista.length > 10 ? motorista.substring(0, 10) + '...' : motorista;
        };

        return (
            <div className="chamada-resumo">
                <div className="chamada-resumo-vehicle">
                    Placa:<strong>{chamada.romaneio.placaVeiculo}</strong>
                </div>
                <div className="chamada-resumo-driver">
                    Motorista:<strong>{truncateMotorista(chamada.romaneio.motoristaNome)}</strong>
                </div>
                <div className="chamada-resumo-type">
                    Tipo:<strong>{chamada.notificationType}</strong>
                </div>
            </div>
        );
    }

    function MoegaDisplay({ moega, romaneios }) {
        return (
            <div className="moega-display">
                <div className="moega-content">
                    <span className="moega-title">{moega.toUpperCase()}</span>
                    <Divider />
                    {romaneios.map((romaneio, index) => (
                        <span key={index} className="moega-item">
                            {index + 1} - {romaneio.placaVeiculo}
                        </span>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className="chamada-container">
            {connectionStatus !== 'Conectado' && (
                <header className="chamada-header">
                    <p className="chamada-alert">{connectionStatus}</p>
                </header>
            )}
            <main className="chamada-content">
                {/* Chamada Atual */}
                {typeof chamadas[chamadas.length - 1] === 'object' && (
                    <section className={`chamada-current ${isFlashing ? 'chamada-current-flashing' : ''}`}>
                        <h2 className="chamada-current-title">Chamada Atual</h2>
                        <div className="chamada-current-info">
                            <div className="chamada-current-vehicle">
                                <strong>Placa: </strong> {chamadas[chamadas.length - 1]?.romaneio.placaVeiculo}
                            </div>
                            <div className="chamada-current-driver">
                                <strong>Motorista: </strong> {chamadas[chamadas.length - 1]?.romaneio.motoristaNome}
                            </div>
                            <div className="chamada-current-type">
                                <strong>Tipo: </strong> {chamadas[chamadas.length - 1]?.notificationType}
                            </div>
                        </div>
                    </section>
                )}
                {/* Últimas Chamadas */}
                <section className="chamada-latest">
                    <h2 className="chamada-latest-title">Últimas Chamadas</h2>
                    <div className="chamada-latest-list">
                        {chamadas
                            .slice()
                            .reverse()
                            .map((chamada, index) => (
                                <ChamadaResumo key={index} chamada={chamada} />
                            ))}
                    </div>
                </section>
                {/* Lista de Moedas */}
                <section className="chamada-moegas">
                    <h2 className="chamada-moegas-title">Locais</h2>
                    <div className="chamada-moegas-grid">
                        {Object.keys(orderedRomaneio).map((moega, index) => (
                            <MoegaDisplay key={index} moega={moega} romaneios={orderedRomaneio[moega]} />
                        ))}
                    </div>
                </section>
            </main>
        </div>
    );
}
