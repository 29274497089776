import React from 'react';
import './ListarDescargas.scoped.css';
import { Table, Space, Button, notification } from 'antd';
import { useDescargas, deletarDescarga } from '../../../apis/backend';
import { useModal } from '@ebay/nice-modal-react';
import EditarDescarga from '../EditarDescarga/EditarDescarga';

export default function ListarDescargas() {
    const { descargas, isLoading: isLoadingDescargas, mutate: mutateDescargas } = useDescargas();
    const aprovacaoModal = useModal(EditarDescarga);
    const columns = [
        {
            title: 'Local',
            dataIndex: 'moega',
            key: 'moega',
            ellipsis: true,
        },
        {
            title: 'Produto',
            dataIndex: ['produto', 'descricao'],
            key: 'produtoDescricao',
        },
        {
            title: 'Umidade Inicial',
            dataIndex: 'umidadeInicial',
            key: 'umidadeInicial',
        },
        {
            title: 'Umidade Final',
            dataIndex: 'umidadeFinal',
            key: 'umidadeFinal',
        },
        {
            title: 'Ações',
            dataIndex: '',
            key: '',
            render: (_, a) => (
                <div>
                    <Button type="link" onClick={() => showEdit(a)}>
                        Editar
                    </Button>
                    <Button type="link" onClick={() => onClickDelete(a)}>
                        Deletar
                    </Button>
                </div>
            ),
        },
    ];

    function showEdit(e) {
        aprovacaoModal.show({ descarga: e }).then(() => {
            mutateDescargas();
        });
    }

    async function onClickDelete(e) {
        try {
            await deletarDescarga(e.id);
            mutateDescargas();
            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao deletar entrada/saída.',
            });
        } catch (e) {
            console.error(e);
            notification.error({
                message: 'Erro',
                description: 'Falha ao deletar entrada/saída. Tente novamente',
            });
        }
    }

    return (
        <div>
            <Space size="middle">
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={descargas}
                    loading={isLoadingDescargas}
                    pagination={{
                        simple: false,
                        hideOnSinglePage: true,
                        defaultPageSize: 7,
                        showSizeChanger: false,
                    }}
                />
            </Space>
        </div>
    );
}
