import React, { useState, useEffect } from 'react';
import { useGraphQL } from '../../../apis/backendGraphQL';
import { getLancamentosCredito, getLancamentosDebito } from '../../../apis/queries/graphQueries';
import { Table, Space, Select, Input, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { lancamentoStatus, getCustomerName, formatDayDate, searchInArray, formatCurrencyTwo } from '../../Utils/Utils';

const { Search } = Input;

export default function ListagemLancamentos() {
    const columns = [
        {
            title: 'Vencimento',
            key: 'vencimento',
            ellipsis: true,
            render: (_, record) => formatDayDate(record.vencimento, true),
        },
        {
            title: 'Cliente',
            key: 'cliente',
            render: (value) => (
                <Link target="_blank" to={`/cadastros/cliente/${value.cliente?.id}`}>
                    {getCustomerName(value.cliente)}
                </Link>
            ),
            ellipsis: true,
        },
        {
            title: 'Descrição',
            key: 'descricao',
            ellipsis: true,
            render: (_, record) => <Link to={`/financeiro/lancamento/${record.id}`}>{record.descricao}</Link>,
        },
        {
            title: 'Categoria',
            key: 'categoria',
            dataIndex: 'categoria',
            ellipsis: true,
        },
        {
            title: 'Tipo',
            key: 'tipo',
            dataIndex: 'tipo',
            ellipsis: true,
        },
        {
            title: 'Valor Atual',
            key: 'missingRelatedValue',
            dataIndex: 'missingRelatedValue',
            render: (_, record) => <p style={{ color: 'green' }}>{formatCurrencyTwo(record.missingRelatedValue)}</p>,
            ellipsis: true,
        },
    ];
    const items = [
        {
            key: '1',
            label: 'Contas a Receber',
            children: <ContasAReceber />,
        },
        {
            key: '2',
            label: 'Contas a Pagar',
            children: <ContasAPagar />,
        },
    ];

    function ContasAReceber() {
        const [type, setType] = useState(lancamentoStatus[0].value);
        const [lancamentosByTypeCredito, setLancamentosByTypeCredito] = useState([]);
        const { data: dataCredito, isLoading: isLoadingCredito } = useGraphQL(getLancamentosCredito);

        useEffect(() => {
            if (dataCredito && Array.isArray(dataCredito?.lancamentos)) {
                setLancamentosByTypeCredito(dataCredito?.lancamentos?.filter((x) => x.status === type));
            }
        }, [dataCredito]);

        useEffect(() => {
            if (dataCredito && Array.isArray(dataCredito?.lancamentos)) {
                setLancamentosByTypeCredito(dataCredito?.lancamentos?.filter((x) => x.status === type));
            }
        }, [type]);

        function onSearchCredito(evt) {
            const searchTerm = evt.target.value;

            if (!searchTerm) return setLancamentosByTypeCredito(dataCredito.lancamentos.filter((x) => x.status === type));

            setLancamentosByTypeCredito(searchInArray(searchTerm, lancamentosByTypeCredito));
        }

        return (
            <div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <div style={{ width: '20%', paddingRight: '10px' }}>
                        <Select placeholder="Tipos" onChange={setType} options={lancamentoStatus} style={{ width: '100%' }} defaultValue={lancamentoStatus[0]} size="medium" />
                    </div>
                    <div style={{ width: '80%' }}>
                        <Search placeholder="Busca" allowClear size="medium" onChange={onSearchCredito} style={{ width: '100%' }} />
                    </div>
                </div>
                <Space size="middle">
                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={lancamentosByTypeCredito}
                        loading={isLoadingCredito}
                        pagination={{
                            simple: false,
                            hideOnSinglePage: true,
                            defaultPageSize: 10,
                            showSizeChanger: false,
                        }}
                    />
                </Space>
            </div>
        );
    }

    function ContasAPagar() {
        const columnsPagar = [
            {
                title: 'Descrição',
                key: 'descricao',
                ellipsis: true,
                render: (_, record) => <Link to={`/financeiro/lancamento/${record.id}`}>{record.descricao}</Link>,
            },
            {
                title: 'NF',
                key: 'nf',
                dataIndex: 'nf',
                ellipsis: true,
            },
            {
                title: 'Fornecedor',
                key: 'fornecedor',
                render: (value) => (
                    <Link target="_blank" to={`/cadastros/cliente/${value.fornecedor?.id}`}>
                        {getCustomerName(value.fornecedor)}
                    </Link>
                ),
                ellipsis: true,
            },
            {
                title: 'Valor Atual',
                key: 'missingRelatedValue',
                dataIndex: 'missingRelatedValue',
                render: (_, record) => <p style={{ color: 'red' }}>{formatCurrencyTwo(record.missingRelatedValue)}</p>,
                ellipsis: true,
            },
            {
                title: 'Vencimento',
                key: 'vencimento',
                ellipsis: true,
                render: (_, record) => formatDayDate(record.vencimento, true),
            },
            {
                title: 'Categoria',
                key: 'categoria',
                dataIndex: 'categoria',
                ellipsis: true,
            },
        ];
        const [type, setType] = useState(lancamentoStatus[0].value);
        const { data: dataDebito, isLoading: isLoadingDebito } = useGraphQL(getLancamentosDebito);
        const [lancamentosByTypeDebito, setLancamentosByTypeDebito] = useState([]);

        useEffect(() => {
            if (dataDebito && Array.isArray(dataDebito?.lancamentos)) {
                setLancamentosByTypeDebito(dataDebito?.lancamentos?.filter((x) => x.status === type));
            }
        }, [dataDebito]);

        useEffect(() => {
            if (dataDebito && Array.isArray(dataDebito?.lancamentos)) {
                setLancamentosByTypeDebito(dataDebito?.lancamentos?.filter((x) => x.status === type));
            }
        }, [type]);

        function onSearchDebito(evt) {
            const searchTerm = evt.target.value;

            if (!searchTerm) return setLancamentosByTypeDebito(dataDebito.lancamentos.filter((x) => x.status === type));

            setLancamentosByTypeDebito(searchInArray(searchTerm, lancamentosByTypeDebito));
        }

        return (
            <div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <div style={{ width: '20%', paddingRight: '10px' }}>
                        <Select placeholder="Tipos" onChange={setType} options={lancamentoStatus} style={{ width: '100%' }} defaultValue={lancamentoStatus[0]} size="medium" />
                    </div>
                    <div style={{ width: '80%' }}>
                        <Search placeholder="Busca" allowClear size="medium" onChange={onSearchDebito} style={{ width: '100%' }} />
                    </div>
                </div>
                <Space size="middle">
                    <Table
                        rowKey="id"
                        columns={columnsPagar}
                        dataSource={lancamentosByTypeDebito}
                        loading={isLoadingDebito}
                        pagination={{
                            simple: false,
                            hideOnSinglePage: true,
                            defaultPageSize: 10,
                            showSizeChanger: false,
                        }}
                    />
                </Space>
            </div>
        );
    }

    return <Tabs defaultActiveKey="1" items={items} destroyInactiveTabPane={true} />;
}
