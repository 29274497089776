import React, { useState, useEffect, useRef } from 'react';
import { Select, DatePicker, Button, Table, Tooltip, notification } from 'antd';
import { DownloadOutlined, PrinterOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import locale from 'antd/lib/locale/pt_BR';
import { useRelatorioTransgenia, useClientesRomaneio, postTransgenia } from '../../apis/backend';
import { formatRoundedNumber, transgeniaOptions, getCustomerName, filterSelect } from '../Utils/Utils';
import ReactToPrint from 'react-to-print';
import { CSVLink } from 'react-csv';

const { RangePicker } = DatePicker;

export default function RelatorioTransgenia() {
    const [clientes, setClientes] = useState([]);
    const [range, setRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
    const { clientes: clientesDb } = useClientesRomaneio();
    const [isLoading, setIsLoading] = useState(false);
    const [fixedData, setFixedData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [typeSelected, setTypeSelected] = useState(transgeniaOptions[0].value);
    const { relatorio, isLoading: isFetching, mutate } = useRelatorioTransgenia(range[0].startOf('day').toISOString(), range[1].endOf('day').toISOString(), clientes, typeSelected);
    const tableRef = useRef();
    const columns = [
        {
            title: 'Posição',
            align: 'center',
            dataIndex: 'posicao',
            key: 'posicao',
            render: (x, rec) => <div style={{ backgroundColor: rec.isLancadoTransgenia ? 'green' : 'red', height: '100%' }}>{x}</div>,
        },
        { title: 'Cliente', align: 'center', dataIndex: 'cliente', key: 'cliente' },
        { title: 'Placa', align: 'center', dataIndex: 'placaVeiculo', key: 'placaVeiculo' },
        { title: 'Peso', align: 'center', dataIndex: 'pesoLiquido', key: 'pesoLiquido' },
        { title: 'Transgenia', align: 'center', dataIndex: 'transgenia', key: 'transgenia' },
        { title: 'Saída', align: 'center', dataIndex: 'dataSaida', key: 'dataSaida' },
        {
            title: 'Ações',
            align: 'center',
            dataIndex: 'acoes',
            key: 'acoes',
            render: (_, record) => (
                <div>
                    <Tooltip title={record.isLancadoTransgenia ? 'Remover Lançamento' : 'Lançar'}>
                        <Button
                            style={{ color: record.isLancadoTransgenia ? 'green' : 'red' }}
                            shape="circle"
                            icon={record.isLancadoTransgenia ? <CloseOutlined /> : <CheckOutlined />}
                            onClick={() => onClickTransgenia(record)}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];
    const headers = [
        { label: 'Posição', key: 'posicao' },
        { label: 'Cliente', key: 'cliente' },
        { label: 'Placa', key: 'placaVeiculo' },
        { label: 'Peso', key: 'pesoLiquido' },
        { label: 'Transgenia', key: 'transgenia' },
        { label: 'Data', key: 'dataSaida' },
    ];

    const onClickTransgenia = async (record) => {
        console.log(record);
        try {
            await postTransgenia(record.id);
            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao alterar o lançamento.',
            });
            mutate();
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Erro',
                description: 'Falha ao alterar o registro. Tente novamente',
            });
        }
    };

    useEffect(() => {
        if (Array.isArray(relatorio)) {
            setIsLoading(true);
            let valor = 0;
            const arrFixed = [];

            relatorio.forEach((x, idx) => {
                valor = valor + x.pesoLiquido;
                arrFixed.push({
                    posicao: idx + 1,
                    cliente: getCustomerName(x.cliente),
                    pesoLiquido: formatRoundedNumber(x.pesoLiquido),
                    transgenia: x.transgenia,
                    dataSaida: `${dayjs(x.dataSaida + 'Z').format('DD/MM/YYYY')}`,
                    placaVeiculo: x.placaVeiculo,
                    id: x.id,
                    isLancadoTransgenia: x.isLancadoTransgenia,
                });
            });

            setTotalData(valor);
            setFixedData(arrFixed);
            setIsLoading(false);
        }
    }, [relatorio]);

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <RangePicker
                    allowClear={false}
                    locale={locale}
                    format={'DD/MM/YYYY'}
                    defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
                    style={{ width: '25%', height: '100%' }}
                    size="medium"
                    onChange={setRange}
                />
                <Select
                    size="medium"
                    placeholder="Cliente"
                    mode="multiple"
                    showSearch
                    allowClear
                    style={{ width: '60%', paddingLeft: '5px', paddingRight: '5px', height: '100%' }}
                    onChange={setClientes}
                    filterOption={filterSelect}
                    options={clientesDb}
                />
                <Select
                    size="medium"
                    placeholder="Transgenia"
                    allowClear
                    style={{ width: '35%', paddingRight: '5px', height: '100%' }}
                    defaultValue={transgeniaOptions[0]}
                    options={transgeniaOptions}
                    onChange={setTypeSelected}
                    mode="multiple"
                />
                <CSVLink data={fixedData} headers={headers} separator={';'} filename={'transgenia.csv'}>
                    <Button type="primary" icon={<DownloadOutlined />} size="medium" style={{ marginRight: '1px' }} />
                </CSVLink>
                <ReactToPrint
                    trigger={() => <Button type="primary" size="medium" style={{ width: '38px' }} icon={<PrinterOutlined />} disabled={!relatorio} />}
                    content={() => tableRef.current}
                />
            </div>
            <div style={{ marginTop: '10px' }}>
                <Table
                    rowKey="id"
                    ref={tableRef}
                    columns={columns}
                    dataSource={fixedData}
                    pagination={false}
                    loading={isLoading || isFetching}
                    bordered
                    summary={() => (
                        <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell>Total</Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={6}>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>{formatRoundedNumber(totalData)}</div>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </div>
        </div>
    );
}
