import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Result } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';

import Layout from './components/Layout/Layout';
import Home from './pages/Home';
import Produto from './pages/Cadastros/Produto/Produto';
import Cliente from './pages/Cadastros/Cliente/Cliente';
import OperacaoFiscal from './pages/Cadastros/OperacaoFiscal/OperacaoFiscal';
import EntradaSaida from './pages/Gerenciamento/GestaoBalanca/GestaoBalanca';
import Operacoes from './pages/Gerenciamento/Operacoes/Operacoes';
import Descarga from './pages/Expediente/Descarga/Descarga';
import Balanca from './pages/Movimento/Balanca/Balanca';
import SimplesPesagem from './pages/Movimento/SimplesPesagem/SimplesPesagem';
import Relatorios from './pages/Relatorios';
import ProdutoView from './pages/Views/Produto/Produto';
import ClienteView from './pages/Views/Cliente/Cliente';
import OperacaoFiscalView from './pages/Views/OperacaoFiscal/OperacaoFiscal';
import Login from './components/Auth/Login/Login';
import ContratoView from './pages/Views/Contrato/Contrato';
import Contrato from './pages/Cadastros/Contrato/Contrato';
import Lancamentos from './pages/Financeiro/Lancamentos/Lancamentos';
import Caixas from './pages/Financeiro/Caixas/Caixas';
import Caixa from './pages/Views/Caixa/Caixa';
import Lancamento from './pages/Views/Lancamento/Lancamento';
import DashboardFinanceiro from './pages/Financeiro/DashboardFinanceiro/DashboardFinanceiro';
import Chamada from './pages/Chamada';

import NiceModal from '@ebay/nice-modal-react';

const AuthenticatedComponents = () => {
    return (
        <BrowserRouter>
            <Layout>
                <NiceModal.Provider>
                    <Routes>
                        <Route path="/" element={<Home />} exact />
                        <Route path="/cadastros/produto" element={<Produto />} exact />
                        <Route path="/cadastros/produto/:id" element={<ProdutoView />} exact />
                        <Route path="/cadastros/cliente" element={<Cliente />} exact />
                        <Route path="/cadastros/cliente/:id" element={<ClienteView />} exact />
                        <Route path="/cadastros/operacao-fiscal" element={<OperacaoFiscal />} exact />
                        <Route path="/cadastros/operacao-fiscal/:id" element={<OperacaoFiscalView />} exact />
                        <Route path="/cadastros/contrato" element={<Contrato />} exact />
                        <Route path="/cadastros/contrato/:id" element={<ContratoView />} exact />
                        <Route path="/expediente/entrada-saida" element={<Descarga />} exact />
                        <Route path="/movimento/balanca" element={<Balanca />} exact />
                        <Route path="/movimento/simples-pesagem" element={<SimplesPesagem />} exact />
                        <Route path="/gerenciamento/gestao-balanca" element={<EntradaSaida />} exact />
                        <Route path="/gerenciamento/operacoes" element={<Operacoes />} exact />
                        <Route path="/financeiro/dashboard" element={<DashboardFinanceiro />} exact />
                        <Route path="/financeiro/lancamentos" element={<Lancamentos />} exact />
                        <Route path="/financeiro/lancamento/:id" element={<Lancamento />} exact />
                        <Route path="/financeiro/caixas" element={<Caixas />} exact />
                        <Route path="/financeiro/caixa/:id" element={<Caixa />} exact />
                        <Route path="/relatorios" element={<Relatorios />} exact />
                        <Route path="/chamada" element={<Chamada />} exact />
                    </Routes>
                </NiceModal.Provider>
            </Layout>
        </BrowserRouter>
    );
};

const Redirecionando = () => {
    return (
        <div>
            <Result title="Redirecionando" icon={<LoadingOutlined style={{ fontSize: '100px', color: '#067e14' }} />} />
        </div>
    );
};

const NavigationRouter = () => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    async function setToken() {
        const token = await getAccessTokenSilently({
            scope: 'openid email profile',
            audience: 'backoffice.limaetarifa.com.br',
        });

        localStorage.setItem('JWT_TOKEN', token);
    }

    useEffect(() => {
        if (isAuthenticated) setToken();
    }, [isAuthenticated, isLoading]);

    return <div>{!isLoading ? isAuthenticated ? <AuthenticatedComponents /> : <Login /> : <Redirecionando />}</div>;
};

export default NavigationRouter;
